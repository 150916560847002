import {createStore} from 'redux';
import {
  parseThreeColListingsFromDom,
  THREE_COL_FEATURED_BOATS,
  THREE_COL_LISTINGS_CONTAINER,
  THREE_COL_SPONSORED_BOATS,
} from '../utils/domParserHelper';
import {EXPRESS_BRIDGE} from '../tppServices/expressBridgeService';

// If something weird happens, just return injectableState
const updateInjectedStateListings = (injectableState, clearListings) => {
  if (clearListings && injectableState.app.data) {
    delete injectableState.app.data.search?.records;
    delete injectableState.app.data.sponsored?.records;
    delete injectableState.app.data.fuzzySponsoredBoats?.records;
    injectableState.app.data.parsableListings = true;
  }
  return injectableState;
};


/**
 * We reduce the amount of data we send to the client by removing the records.
 * They will be parsed from the dom before hydration
 * In case something really weird happens for parsing the dom we can just return the serverSideState
 * on updateInjectedStateListings
 * and forget and generating it from the dom.
 * Stuff will be added to the HTML response, but issues will be avoided
 */
const prepareInjectedReduxState = (serverSideState, options) => {
  let injectableState = serverSideState;

  const parsable = EXPRESS_BRIDGE.LISTING_PARSABLE;
  const clearListings = options?.[parsable];
  injectableState = updateInjectedStateListings(injectableState, clearListings);
  const stringifiedState = JSON.stringify(injectableState).replace(/<\//g,'<\\/');
  return stringifiedState;
};

const addRecordsToState = (state, records, key) => {
  if (records && state.app.data[key]) {
    state.app.data[key].records = records;
  }
  return state;
};

const createStateFromParsedDom = (initialState) => {
  try {
    const parsedListings = parseThreeColListingsFromDom();
    initialState = addRecordsToState(initialState, parsedListings[THREE_COL_LISTINGS_CONTAINER], 'search');
    initialState = addRecordsToState(initialState, parsedListings[THREE_COL_FEATURED_BOATS], 'sponsored');
    initialState = addRecordsToState(initialState, parsedListings[THREE_COL_SPONSORED_BOATS], 'fuzzySponsoredBoats');

    return initialState;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error parsing DOM:', e);
    return initialState;
  }
};

const createServerStore = (connectedReducers, composedEnhancers) => {
  const initialState = {};
  const store = createStore(connectedReducers, initialState, composedEnhancers);
  return store;
};

/**
 * In case something really weird happens for parsing the dom we can avoid the parsing by just returning
 * the original serverSideState on updateInjectedStateListings. window.__REDUX_STATE__ will have all
 * the listings data and we do not need to parse the dom.
 * Stuff will be added to the HTML response, but issues will be avoided.
 */
const createClientStore = (connectedReducers, composedEnhancers) => {
  let initialState = window.__REDUX_STATE__;
  if (initialState?.app?.data?.parsableListings) {
    initialState = createStateFromParsedDom(initialState);
    delete initialState.app.data.parsableListings;
  }
  const store = createStore(connectedReducers, initialState, composedEnhancers);
  return store;
};

export {createServerStore, createClientStore, prepareInjectedReduxState, addRecordsToState};
