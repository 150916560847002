import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import split from 'lodash/split';
import last from 'lodash/last';
import * as actions from '../../store/actions/dataLayer';
import { getPageParams } from '../../utils/dataLayerHelper';
import {
  getCurrentLocale,
  isLanguageSupportByPortal
} from '../../utils/language';
import { ENGLISH_LOCALES } from '../../constants';
import {useTPPServices} from '../../tppServices/tppDIHooks';

const DataLayer = (props) => {
  const [state, setState] = useState({});
  const {tpp} = useTPPServices();
  useEffect(() => {
    updateDataLayer();
  }, []);

  useEffect(() => {
    updateDataLayer();
    updatePostDataLayer();
  });
  const getFullPath = () => {
    const { location } = props;
    const pathname = get(location, 'pathname', '');
    const search = get(location, 'search', '');
    return `${pathname}${search}`;
  };
  const updateDataLayer = async () => {
    const fullPath = getFullPath();
    const config = tpp.configService().getConfig();
    //only update Data Layer once per path change
    if (state.fullPath === fullPath) {
      return;
    }
    setState((oldState) => ({ ...oldState, fullPath, isWorking: true }));
    const pageType = get(props, 'pageType', 'default');
    const pageData = {
      ownerId: get(props, 'data.partyDetails.id', ''),
      category: get(props, 'match.params.category', ''),
      article: get(props, 'match.params.article', '')
    };
    const params = getPageParams(pageType, pageData, config.country, config.fullName);
    const locale = getCurrentLocale(true);
    params.country =
      last(split(locale, '-')) === 'GB' ? 'UK' : last(split(locale, '-'));

    const brandID = get(props, 'brandID', false);
    props.initDataLayer({
      ...params,
      ...(brandID && { brandID })
    });

    // for SRP, normalize the url so it's always English
    // initDataLayer should be loaded first, so it must not perform long sync operations
    // we setDestinationURL and call getEnglishUrl in another promise chain not to block flow
    if (pageType === 'SearchResults') {
      getEnglishUrl({ pathname: fullPath, language: 'en', pageType: 'BOATS' }).then((englishUrl) => {
        const destinationURL = englishUrl?.replace('/en', '');
        props.setDestinationURL(destinationURL);
      });
    }
  };
  const updatePostDataLayer = () => {
    if (!state.isWorking) {
      return;
    }
    if (!props.isWorking) {
      setState((oldState) => ({ ...oldState, isWorking: false }));
      const fullPath = getFullPath();
      props.registerPageView({ pathname: fullPath });
      props.registerProductImpressions();
      props.registerListingView();
    }
  };
  const getEnglishUrl = async ({ pathname }) => {
    // us, uk and au are to support yachtworld
    const englishValSupport = ENGLISH_LOCALES.find((val) =>
      isLanguageSupportByPortal(val)
    );
    if (!englishValSupport) {
      return;
    }
    const language = englishValSupport;
    const englishUrl = await tpp.translateUrl(language, pathname);
    return englishUrl;
  };
  return null;
};

DataLayer.propTypes = {
  initDataLayer: PropTypes.func.isRequired,
  setDestinationURL: PropTypes.func.isRequired,
  isWorking: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  pageType: PropTypes.string,
  registerPageView: PropTypes.func.isRequired,
  registerProductImpressions: PropTypes.func.isRequired,
  registerListingView: PropTypes.func.isRequired,
  brandID: PropTypes.number
};

const connectedDataLayer = connect(
  (state) => ({
    isWorking: state.app.isWorking,
    data: state.app.data
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...actions
      },
      dispatch
    )
)(DataLayer);

const connectedToRouter = withRouter(connectedDataLayer);

export default connectedToRouter;
