import { FEATURE_FLAG_KEYS, isFeatureFlagActive } from '../context/ABTestContext';
import { hasAdvertisingConsent } from '../utils/cookies';
import { getBoatDetailsQueryString, prepareBoatDataListing } from '../utils/requestHelpers';
import * as httpClient from '../utils/httpClient';

export const getBoatDetails = async (id, config, cookies, abContext, requestContext) => {
  const supportsNewBDP = !!config?.supports?.enableNewBDP;
  const featureFlagNewBDP = isFeatureFlagActive( FEATURE_FLAG_KEYS.NEW_BDP, abContext?.featureFlags, cookies ) && supportsNewBDP;
  const hasTargetedAdsConsent = hasAdvertisingConsent(cookies || {});
  try {
    const boatDetailsQuery = getBoatDetailsQueryString(id, config, featureFlagNewBDP, hasTargetedAdsConsent);
    const apiClient = httpClient.getHttpClient(requestContext?.headers);
    const response = await apiClient.get(boatDetailsQuery);
    const boatDetails = prepareBoatDataListing(response);
    return { boatDetails, error: null };
  } catch (err) {
    return { boatDetails: null, error: err };
  }
};
