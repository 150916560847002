/* eslint-disable no-useless-escape, max-len */
import { matchPath } from 'react-router';

import { UsaStates as states } from 'usa-states';
import { getMessages } from '../../tppServices/translations/messages';

const localizedUrlCountryCodes = ['ES', 'IT', 'US', 'CA'];
const noEncoding = (self) => self;

export const localizedSubdivisionUrlRegex = (
  constants,
  allowedChars,
  countryCodes = localizedUrlCountryCodes,
  encodingFn = noEncoding
) => {
  const allowed = `[${allowedChars}%$]`;
  const localizedCountries = countryCodes
    .map((code) => {
      const key = `SEARCH_URL_ITEM_SUBDIVISION_${code}`;
      const subdivision = encodingFn(constants[key]);
      return `${subdivision}\-${allowed}+`;
    })
    .join('|');

  const localizedRegex = `${encodingFn(
    constants.SEARCH_URL_ITEM_SUBDIVISION
  )}\-${allowed}+`;
  return `${localizedRegex}|${localizedCountries}`;
};

/**
 * When we add a country with localized urls depending on language selected and country selected
 * we can add its code to this array.
 * @returns {string[]}
 */
export const getLocalizedCountryCodes = () => ['es', 'it'];

export const allowedCharactersInUrl = "a-zA-Zа-яА-ЯΆΈ-ϗἀ-῾ÂÇÎÓÖÛÜâåçîóöûüÄäĄąĆćčđĘęëĞğİıŁłŃńŚśßŞşšŹźŻżž0-9&-.,'æäéàèùøáúñ​òêôû";
export const keywordSearchPattern = `[ -.|0-~|\xa1-\xff|${allowedCharactersInUrl}]`;
export const searchTypes = {
  boats: 'boats',
  brandedOem: 'brandedOem',
  engines: 'engines'
};

export const getBoatsConstants = (routesConstants = {}) => {
  let config = routesConstants;

  // NOTE: This list is not in a full RegExp pattern as it might need to start with ^ in some cases.
  // NOTE: This one is very important, as ALLOWED_CHARACTERS_IN_URL is also used to removed undesired characters,
  //       an % or a $ symbol needs to be manually added where encoded params are tested.
  const ALLOWED_CHARACTERS_IN_URL = allowedCharactersInUrl;
  const ALLOWED_CHARACTERS_IN_BLOG_CATEGORY_URL = 'a-zA-Z0-9 ';
  const KEYWORD_SEARCH_PATTERN = `[ -.|0-~|\xa1-\xff|${ALLOWED_CHARACTERS_IN_URL}]`;
  const LISTING_SAME_DEALER = 'same seller listing';
  const LISTING_SIMILAR_LISTING = 'similar listing';
  const LISTING_MULTI_CONTACT = 'multi contact listing';
  const LISTING_ENHANCED = 'enhanced listing';
  const LISTING_SPONSORED = 'sponsored listing';
  const LISTING_FEATURED = 'featured listing';
  const LISTING_PREMIUM_BROKER = 'premium broker banner';
  const LISTING_MANUFACTURER = 'manufacturer listing';
  const LISTING_SUGGESTED = 'suggested listing';
  const LISTING_OEM_BRAND = 'oem brand listing';
  const LISTING_STANDARD = 'standard listing';
  const LISTING_RESIZES = {
    default: { width: 300, height: 222 },
    rowEnhanced: { width: 400, height: 267 },
    cardMobile: { width: 300, height: 194 },
    cardTablet: { width: 229, height: 120 },
    cardDesktop: { width: 296, height: 194 }
  };
  const FIFTH_ROW_POSITION = 10;
  const SRP_MAXIMUM_ENGINES_NUMBER_SEARCH = 4;

  const localizedCountries = ['ES', 'IT', 'US', 'CA'];
  const subdivisionRe = localizedSubdivisionUrlRegex(
    config,
    ALLOWED_CHARACTERS_IN_URL,
    localizedCountries
  );
  const subdivisionReEncoded = localizedSubdivisionUrlRegex(
    config,
    ALLOWED_CHARACTERS_IN_URL,
    localizedCountries,
    encodeURIComponent
  );

  const _searchParamsPatterns = [
    `:condition(${config.SEARCH_URL_ITEM_CONDITION}\-[^/]+)?/`,
    `:types(${config.SEARCH_URL_ITEM_TYPES}-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:classes(${config.SEARCH_URL_ITEM_CLASS}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:group(${config.SEARCH_URL_ITEM_CLASSGROUP}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:make(${config.SEARCH_URL_ITEM_MAKE}\-[^/]+)?/`,
    `:modelRange(${config.SEARCH_URL_ITEM_MODELRANGE}\-[^/]+)?/`,
    `:model(${config.SEARCH_URL_ITEM_MODEL}\-[^/]+)?/`,
    `:worldRegion(${config.SEARCH_URL_ITEM_WORLD_REGION}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:country(${config.SEARCH_URL_ITEM_COUNTRY}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:region(${config.SEARCH_URL_ITEM_REGION}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:subdivision(${subdivisionRe})?/`,
    `:city(${config.SEARCH_URL_ITEM_CITY}\-[^/]+)?/`,
    `:postalCode(${config.SEARCH_URL_ITEM_POSTALCODE}\-[0-9]+)?/`,
    `:by(${config.SEARCH_URL_ITEM_BY}\-[a-z|0-9|%-]+)?/`,
    `:radius(${config.SEARCH_URL_ITEM_RADIUS}\-[0-9|${config.SEARCH_URL_ITEM_RADIUS_EXACT}|${config.SEARCH_URL_ITEM_RADIUS_ANY}]+)?/`,
    `:makeModel(${config.SEARCH_URL_ITEM_MAKEMODEL}\-[^/]+)?/`,
    `:engine(${config.SEARCH_URL_ITEM_ENGINE}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:engineOneHours(${config.SEARCH_URL_ITEM_ENGINE_HOURS}\-[0-9|,]+)?/`,
    `:length(${config.SEARCH_URL_ITEM_LENGTH}\-[0-9|,]+)?/`,
    `:price(${config.SEARCH_URL_ITEM_PRICE}\-[0-9|,]+)?/`,
    `:currency(currency\-[A-Z]{3})?/`,
    `:year(${config.SEARCH_URL_ITEM_YEAR}\-[0-9|,]+)?/`,
    `:fuelType(${config.SEARCH_URL_ITEM_FUEL}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:hullMaterial(${config.SEARCH_URL_ITEM_HULL}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:numberOfEngines(${config.SEARCH_URL_ITEM_ENGINE_NUMBER}\-[0-9])?/`,
    `:page(${config.SEARCH_URL_ITEM_PAGE}\-[0-9]+)?/`,
    `:sort(${config.SEARCH_URL_ITEM_SORT}\-[^/]+:[^/]+)?/`,
    `:keyword(%?3?F?${config.SEARCH_URL_ITEM_KEYWORD}[\-|\=]${KEYWORD_SEARCH_PATTERN}+)?/`,
    ':modal(modal-[a-z|+]+)?/'
  ];

  const _searchOemParamsPatterns = [
    `:make(${config.SEARCH_URL_ITEM_MAKE}\-[^/]+)?/`,
    `:modelRange(${config.SEARCH_URL_ITEM_MODELRANGE}\-[^/]+)?/`,
    `:model(${config.SEARCH_URL_ITEM_MODEL}\-[^/]+)?/`,
    `:length(${config.SEARCH_URL_ITEM_LENGTH}\-[0-9|,]+)?/`,
    `:radius(${config.SEARCH_URL_ITEM_RADIUS}\-[0-9|${config.SEARCH_URL_ITEM_RADIUS_EXACT}|${config.SEARCH_URL_ITEM_RADIUS_ANY}]+)?/`,
    `:page(${config.SEARCH_URL_ITEM_PAGE}\-[0-9]+)?/`,
    `:sort(${config.SEARCH_URL_ITEM_SORT}\-[^/]+:[^/]+)?/`,
    `:keyword(%?3?F?${config.SEARCH_URL_ITEM_KEYWORD}[\-|\=]${KEYWORD_SEARCH_PATTERN}+)?/`,
    ':modal(modal-[a-z|+]+)?/'
  ];

  const _generateSearchParamsPatterns = [
    `:condition(${encodeURIComponent(
      config.SEARCH_URL_ITEM_CONDITION
    )}\-[a-z|0-9|+|%|,|-]+)?/`,
    `:types(${encodeURIComponent(
      config.SEARCH_URL_ITEM_TYPES
    )}-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:classes(${encodeURIComponent(
      config.SEARCH_URL_ITEM_CLASS
    )}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:group(${encodeURIComponent(config.SEARCH_URL_ITEM_CLASSGROUP)}\-[^/]+)?/`,
    `:make(${encodeURIComponent(config.SEARCH_URL_ITEM_MAKE)}\-[^/]+)?/`,
    `:modelRange(${encodeURIComponent(
      config.SEARCH_URL_ITEM_MODELRANGE
    )}\-[^/]+)?/`,
    `:model(${encodeURIComponent(config.SEARCH_URL_ITEM_MODEL)}\-[^/]+)?/`,
    `:worldRegion(${encodeURIComponent(
      config.SEARCH_URL_ITEM_WORLD_REGION
    )}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:country(${encodeURIComponent(
      config.SEARCH_URL_ITEM_COUNTRY
    )}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:region(${encodeURIComponent(
      config.SEARCH_URL_ITEM_REGION
    )}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:subdivision(${subdivisionReEncoded})?/`,
    `:city(${encodeURIComponent(config.SEARCH_URL_ITEM_CITY)}\-[^/]+)?/`,
    `:postalCode(${encodeURIComponent(
      config.SEARCH_URL_ITEM_POSTALCODE
    )}\-[0-9]+)?/`,
    `:by(${encodeURIComponent(config.SEARCH_URL_ITEM_BY)}\-[a-z|0-9|%-]+)?/`,
    `:radius(${encodeURIComponent(config.SEARCH_URL_ITEM_RADIUS)}\-[0-9|${
      config.SEARCH_URL_ITEM_RADIUS_EXACT
    }|${config.SEARCH_URL_ITEM_RADIUS_ANY}]+)?/`,
    `:makeModel(${encodeURIComponent(
      config.SEARCH_URL_ITEM_MAKEMODEL
    )}\-[^/]+)?/`,
    `:engine(${encodeURIComponent(
      config.SEARCH_URL_ITEM_ENGINE
    )}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:engineOneHours(${encodeURIComponent(config.SEARCH_URL_ITEM_ENGINE_HOURS)}\-[0-9|,]+)?/`,
    `:length(${encodeURIComponent(config.SEARCH_URL_ITEM_LENGTH)}\-[0-9|,]+)?/`,
    `:price(${encodeURIComponent(config.SEARCH_URL_ITEM_PRICE)}\-[0-9|,]+)?/`,
    `:currency(currency\-[A-Z]{3})?/`,
    `:year(${encodeURIComponent(config.SEARCH_URL_ITEM_YEAR)}\-[0-9|,]+)?/`,
    `:fuelType(${encodeURIComponent(
      config.SEARCH_URL_ITEM_FUEL
    )}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:hullMaterial(${encodeURIComponent(
      config.SEARCH_URL_ITEM_HULL
    )}\-[${ALLOWED_CHARACTERS_IN_URL}|+|%]+)?/`,
    `:numberOfEngines(${encodeURIComponent(config.SEARCH_URL_ITEM_ENGINE_NUMBER)}\-[0-9])?/`,
    `:page(${encodeURIComponent(config.SEARCH_URL_ITEM_PAGE)}\-[0-9]+)?/`,
    `:sort(${encodeURIComponent(config.SEARCH_URL_ITEM_SORT)}\-[^/]+:[^/]+)?/`,
    `:keyword(%?3?F?${encodeURIComponent(
      config.SEARCH_URL_ITEM_KEYWORD
    )}[\-|\=]${KEYWORD_SEARCH_PATTERN}+)?/`,
    ':modal(modal-[a-z|+]+)?/'
  ];

  const _generateBrandedOemSearchParamsPatterns = [
    `:model(${encodeURIComponent(config.SEARCH_URL_ITEM_MODEL)}\-[^/]+)?/`,
    `:by(${encodeURIComponent(config.SEARCH_URL_ITEM_BY)}\-[a-z|0-9|%-]+)?/`,
    `:radius(${encodeURIComponent(config.SEARCH_URL_ITEM_RADIUS)}\-[0-9|${
      config.SEARCH_URL_ITEM_RADIUS_EXACT
    }|${config.SEARCH_URL_ITEM_RADIUS_ANY}]+)?/`,
    `:length(${encodeURIComponent(config.SEARCH_URL_ITEM_LENGTH)}\-[0-9|,]+)?/`,
    `:page(${encodeURIComponent(config.SEARCH_URL_ITEM_PAGE)}\-[0-9]+)?/`,
    `:sort(${encodeURIComponent(config.SEARCH_URL_ITEM_SORT)}\-[^/]+:[^/]+)?/`,
    `:keyword(%?3?F?${encodeURIComponent(
      config.SEARCH_URL_ITEM_KEYWORD
    )}[\-|\=]${KEYWORD_SEARCH_PATTERN}+)?/`,
    ':modal(modal-[a-z|+]+)?/'
  ];

  const _engineSearchParamsPatterns = [
    `:condition(${config.SEARCH_URL_ITEM_CONDITION}\-[^/]+)?/`,
    `:category(${config.SEARCH_URL_ITEM_CATEGORY}-[^/:]+)?/`,
    `:types(${config.SEARCH_URL_ITEM_TYPES}-[a-z|0-9|+|%|,|-]+)?/`,
    `:fuel(${config.SEARCH_URL_ITEM_FUEL}\-[^/]+)?/`,
    `:make(${config.SEARCH_URL_ITEM_MAKE}\-[^/]+)?/`,
    `:model(${config.SEARCH_URL_ITEM_MODEL}\-[^/]+)?/`,
    `:worldRegion(${config.SEARCH_URL_ITEM_WORLD_REGION}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:country(${config.SEARCH_URL_ITEM_COUNTRY}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:region(${config.SEARCH_URL_ITEM_REGION}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:subdivision(${config.SEARCH_URL_ITEM_SUBDIVISION}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:city(${config.SEARCH_URL_ITEM_CITY}\-[^/]+)?/`,
    `:classes(${config.SEARCH_URL_ITEM_CLASS}-[a-z0-9\+%, \-]+)?/`,
    `:powerhp(${config.SEARCH_URL_ITEM_POWER_HP}\-[0-9|,]+)?/`,
    `:by(${config.SEARCH_URL_ITEM_BY}\-[a-z|0-9|%-]+)?/`,
    `:makeModel(${config.SEARCH_URL_ITEM_MAKEMODEL}\-[^/]+)?/`,
    `:price(${config.SEARCH_URL_ITEM_PRICE}\-[0-9|,]+)?/`,
    `:year(${config.SEARCH_URL_ITEM_YEAR}\-[0-9|,]+)?/`,
    `:page(${config.SEARCH_URL_ITEM_PAGE}\-[0-9]+)?/`,
    `:sort(${config.SEARCH_URL_ITEM_SORT}\-[^/]+:[^/]+)?/`,
    `:keyword(%?3?F?${config.SEARCH_URL_ITEM_KEYWORD}[\-|\=]${KEYWORD_SEARCH_PATTERN}+)?/`,
    ':modal(modal-[a-z|+]+)?/'
  ];

  const _generateEngineSearchParamsPatterns = [
    `:condition(${encodeURIComponent(
      config.SEARCH_URL_ITEM_CONDITION
    )}\-[a-z|0-9|+|%|,|-]+)?/`,
    `:category(${encodeURIComponent(
      config.SEARCH_URL_ITEM_CATEGORY
    )}-[^/:]+)?/`,
    `:types(${encodeURIComponent(
      config.SEARCH_URL_ITEM_TYPES
    )}-[a-z|0-9|+|%|,|-]+)?/`,
    `:fuel(${encodeURIComponent(config.SEARCH_URL_ITEM_FUEL)}\-[^/]+)?/`,
    `:make(${encodeURIComponent(config.SEARCH_URL_ITEM_MAKE)}\-[^/]+)?/`,
    `:model(${encodeURIComponent(config.SEARCH_URL_ITEM_MODEL)}\-[^/]+)?/`,
    `:worldRegion(${encodeURIComponent(
      config.SEARCH_URL_ITEM_WORLD_REGION
    )}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:country(${encodeURIComponent(
      config.SEARCH_URL_ITEM_COUNTRY
    )}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:region(${encodeURIComponent(
      config.SEARCH_URL_ITEM_REGION
    )}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:subdivision(${encodeURIComponent(
      config.SEARCH_URL_ITEM_SUBDIVISION
    )}\-[${ALLOWED_CHARACTERS_IN_URL}%$]+)?/`,
    `:city(${encodeURIComponent(config.SEARCH_URL_ITEM_CITY)}\-[^/]+)?/`,
    `:classes(${encodeURIComponent(
      config.SEARCH_URL_ITEM_CLASS
    )}-[a-z0-9\+%, \-]+)?/`,
    `:powerhp(${encodeURIComponent(
      config.SEARCH_URL_ITEM_POWER_HP
    )}\-[0-9|,]+)?/`,
    `:by(${encodeURIComponent(config.SEARCH_URL_ITEM_BY)}\-[a-z|0-9|%-]+)?/`,
    `:makeModel(${encodeURIComponent(
      config.SEARCH_URL_ITEM_MAKEMODEL
    )}\-[^/]+)?/`,
    `:price(${encodeURIComponent(config.SEARCH_URL_ITEM_PRICE)}\-[0-9|,]+)?/`,
    `:year(${encodeURIComponent(config.SEARCH_URL_ITEM_YEAR)}\-[0-9|,]+)?/`,
    `:page(${encodeURIComponent(config.SEARCH_URL_ITEM_PAGE)}\-[0-9]+)?/`,
    `:sort(${encodeURIComponent(config.SEARCH_URL_ITEM_SORT)}\-[^/]+:[^/]+)?/`,
    `:keyword(%?3?F?${encodeURIComponent(
      config.SEARCH_URL_ITEM_KEYWORD
    )}[\-|\=]${KEYWORD_SEARCH_PATTERN}+)?/`,
    ':modal(modal-[a-z|+]+)?/'
  ];

  const SEARCH_URL_PATTERN = [
    `${config.SEARCH_URL_ROOT}`,
    ..._searchParamsPatterns
  ].join('');

  const BRANDED_OEM_SEARCH_URL_PATTERN = [
    `${config.BRANDED_OEM_SEARCH_URL_ROOT}`,
    `:brand([^/]+)/`,
    ..._searchOemParamsPatterns
  ].join('');

  const GENERATE_SEARCH_URL_PATTERN = [
    `${config.SEARCH_URL_ROOT}`,
    ..._generateSearchParamsPatterns
  ].join('');

  const GENERATE_BRANDED_OEM_SEARCH_URL_PATTERN = [
    `${config.BRANDED_OEM_SEARCH_URL_ROOT}`,
    `:brand/`,
    ..._generateBrandedOemSearchParamsPatterns
  ].join('');

  const ENGINES_SEARCH_URL_PATTERN = [
    `${config.ENGINES_SEARCH_URL_ROOT}`,
    ..._engineSearchParamsPatterns
  ].join('');

  const GENERATE_ENGINES_SEARCH_URL_PATTERN = [
    `${config.ENCODED_ENGINES_SEARCH_URL_ROOT}`,
    ..._generateEngineSearchParamsPatterns
  ].join('');

  const DEALER_GALLERY_URL_PATTERN = [
    `${config.DEALER_SEARCH_URL_ROOT}`,
    `:owner(${config.DEALER}-[^/]+)?/`,
    `:rep(${config.SEARCH_URL_ITEM_SALESREP}-[^/]+)?/`,
    ..._searchParamsPatterns
  ].join('');

  const GENERATE_DEALER_GALLERY_URL_PATTERN = [
    `${config.DEALER_SEARCH_URL_ROOT}`,
    `:owner(${config.DEALER}-[^/]+)?/`,
    `:rep(${config.SEARCH_URL_ITEM_SALESREP}-[^/]+)?/`,
    ..._generateSearchParamsPatterns
  ].join('');

  const BRANDED_SEARCH_URL_PATTERN = [
    `${config.BRANDED_SEARCH_URL_ROOT}`,
    ':owner([^/]+)/',
    `:rep(${config.SEARCH_URL_ITEM_SALESREP}-[^/]+)?/`,
    ..._searchParamsPatterns
  ].join('');

  const GENERATE_BRANDED_SEARCH_URL_PATTERN = [
    `${config.BRANDED_SEARCH_URL_ROOT}`,
    ':owner([^/]+)/',
    `:rep(${config.SEARCH_URL_ITEM_SALESREP}-[^/]+)?/`,
    ..._generateSearchParamsPatterns
  ].join('');

  const ENGINES_DEALER_GALLERY_URL_PATTERN = [
    `${config.ENGINES_DEALER_SEARCH_URL_ROOT}`,
    `:owner(${config.DEALER}-[^/]+)?/`,
    ..._engineSearchParamsPatterns
  ].join('');

  const GENERATE_ENGINES_DEALER_GALLERY_URL_PATTERN = [
    `${config.ENGINES_DEALER_SEARCH_URL_ROOT}`,
    `:owner(${config.DEALER}-[^/]+)?/`,
    ..._generateEngineSearchParamsPatterns
  ].join('');

  const ENGINES_BRANDED_SEARCH_URL_PATTERN = [
    `${config.ENGINES_BRANDED_SEARCH_URL_ROOT}`,
    ':owner([^/]+)/',
    ..._engineSearchParamsPatterns
  ].join('');

  const GENERATE_ENGINES_BRANDED_SEARCH_URL_PATTERN = [
    `${config.ENGINES_BRANDED_SEARCH_URL_ROOT}`,
    ':owner([^/]+)/',
    ..._generateEngineSearchParamsPatterns
  ].join('');

  const BLOG_URL_PATTERN = [
    `${config.BLOG_URL_ROOT}`,
    `:category(${config.BLOG_URL_ENCODED_CATEGORIES})?/`,
    `:page(${config.BLOG_URL_ITEM_PAGE}\-[0-9]+)?/`,
    `:article([${ALLOWED_CHARACTERS_IN_URL}_$%]+)?/`
  ].join('');

  const SEARCH_URL_CATEGORY_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_CATEGORY}\-([^/:]+)`
  );

  const SEARCH_URL_TYPE_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_TYPES}\-([${ALLOWED_CHARACTERS_IN_URL}|+|%]+)`
  );
  const SEARCH_URL_CLASS_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_CLASS}\-([${ALLOWED_CHARACTERS_IN_URL}|+|%]+)`
  );
  const SEARCH_URL_CLASS_GROUP_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_CLASSGROUP}\-([^/]+)`
  );
  const SEARCH_URL_MAKE_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_MAKE}\-([^/]+)`
  );
  const SEARCH_URL_MODEL_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_MODEL}\-([^/]+)`
  );
  const SEARCH_URL_MODELRANGE_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_MODELRANGE}\-([^/]+)`
  );
  const SEARCH_URL_SUBDIVISION_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_SUBDIVISION}\-([${ALLOWED_CHARACTERS_IN_URL}]+)`
  );
  const SEARCH_URL_SUBDIVISION_US_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_SUBDIVISION_US}\-([${ALLOWED_CHARACTERS_IN_URL}]+)`
  );
  const SEARCH_URL_SUBDIVISION_CA_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_SUBDIVISION_CA}\-([${ALLOWED_CHARACTERS_IN_URL}]+)`
  );
  const SEARCH_URL_SUBDIVISION_ES_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_SUBDIVISION_ES}\-([${ALLOWED_CHARACTERS_IN_URL}]+)`
  );
  const SEARCH_URL_SUBDIVISION_IT_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_SUBDIVISION_IT}\-([${ALLOWED_CHARACTERS_IN_URL}]+)`
  );
  const SEARCH_URL_REGION_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_REGION}\-([${ALLOWED_CHARACTERS_IN_URL}]+)`
  );
  const SEARCH_URL_COUNTRY_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_COUNTRY}\-([${ALLOWED_CHARACTERS_IN_URL}]+)`
  );
  const SEARCH_URL_CITY_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_CITY}\-([^/]+)`
  );
  const SEARCH_URL_POSTALCODE_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_POSTALCODE}\-([0-9]+)`
  );
  const SEARCH_URL_BY_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_BY}\-([A-Z|a-z|0-9|%-]+)`
  );
  const SEARCH_URL_RADIUS_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_RADIUS}\-([0-9|${config.SEARCH_URL_ITEM_RADIUS_EXACT}|${config.SEARCH_URL_ITEM_RADIUS_ANY}]+)`
  );
  const SEARCH_URL_MAKE_MODEL_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_MAKEMODEL}\-([^/]+)`
  );
  const SEARCH_URL_FUEL_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_FUEL}\-([${ALLOWED_CHARACTERS_IN_URL}|+|%]+)`
  );
  const SEARCH_URL_HULL_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_HULL}\-([${ALLOWED_CHARACTERS_IN_URL}|+|%]+)`
  );
  const SEARCH_URL_WORLD_REGION_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_WORLD_REGION}\-([${ALLOWED_CHARACTERS_IN_URL}|+|%]+)`
  );
  const SEARCH_URL_ENGINETYPE_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_ENGINE}\-([${ALLOWED_CHARACTERS_IN_URL}|+|%]+)`
  );
  const SEARCH_URL_LENGTH_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_LENGTH}\-([0-9]+),?([0-9]+)?`
  );
  const SEARCH_URL_PRICE_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_PRICE}\-([0-9]+),?([0-9]+)?`
  );
  const SEARCH_URL_POWER_HP_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_POWER_HP}\-([0-9]+),?([0-9]+)?`
  );
  const SEARCH_URL_YEAR_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_YEAR}\-([0-9]+)?,?([0-9]+)?`
  );
  const SEARCH_URL_ENGINE_HOURS_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_ENGINE_HOURS}\-([0-9]+)?,?([0-9]+)?`
  );
  const SEARCH_URL_CONDITION_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_CONDITION}\-([^/]+)`
  );
  const SEARCH_URL_KEYWORD_PATTERN = new RegExp(
    `%?3?F?${config.SEARCH_URL_ITEM_KEYWORD}[\-|\=](${KEYWORD_SEARCH_PATTERN}+)`
  );
  const SEARCH_URL_PAGE_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_PAGE}\-([0-9]+)`
  );
  const SEARCH_URL_SORT_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_SORT}\-([^/]+:[^/]+)`
  );
  const SEARCH_URL_MODAL_PATTERN = new RegExp('modal-([a-z|+]+)');
  const SEARCH_URL_ENGINE_NUMBER_PATTERN = new RegExp(
    `${config.SEARCH_URL_ITEM_ENGINE_NUMBER}\-([0-9]+)?`
  );
  const BRANDED_SEARCH_URL_OWNER_PATTERN = new RegExp('(([^/]+)-)?([0-9]+)');
  const BRANDED_SEARCH_URL_REP_PATTERN = new RegExp(
    `(${config.SEARCH_URL_ITEM_SALESREP}-([^/]+)-)?([0-9]+)`
  );
  const BLOG_URL_CATEGORY_PATTERN = new RegExp(
    `(${config.BLOG_URL_ENCODED_CATEGORIES})`
  );
  // eslint-disable-next-line no-misleading-character-class
  const BLOG_URL_ARTICLE_PATTERN = new RegExp(
    `([${ALLOWED_CHARACTERS_IN_URL}_$%]+)`
  );

  const SEARCH_TYPES = {
    boats: 'boats',
    brandedOem: 'brandedOem',
    engines: 'engines'
  };

  const LOCATION_FILTERS_CONFIG = {
    FR: {
      country: true,
      grouping: true,
      subdivision: true,
      city: true,
      forceCities: false
    },
    GB: {
      country: true,
      grouping: true,
      subdivision: true,
      city: true,
      forceCities: false
    },
    DE: {
      country: true,
      grouping: false,
      subdivision: true,
      city: true,
      forceCities: false
    },
    US: {
      country: true,
      grouping: false,
      subdivision: true,
      city: true,
      forceCities: false
    },
    CA: {
      country: true,
      grouping: false,
      subdivision: true,
      city: true,
      forceCities: false
    },
    NL: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    },
    ES: {
      country: true,
      grouping: true,
      subdivision: true,
      city: true,
      forceCities: false
    },
    HR: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    },
    DK: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    },
    GR: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    },
    IT: {
      country: true,
      grouping: true,
      subdivision: true,
      city: true,
      forceCities: false
    },
    PL: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    },
    SE: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    },
    TR: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    },
    NO: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    },
    PT: {
      country: true,
      grouping: false,
      subdivision: false,
      city: true,
      forceCities: false
    }
  };

  const DEFAULT_REFINE_SEARCH = {
    type: 'any',
    radius: '0',
    make: '',
    price: {},
    year: {},
    makeModel: {},
    condition: 'any',
    region: '',
    subdivision: '',
    country: '',
    city: [],
    postalCode: '',
    forSale: 'all',
    length: {},
    model: '',
    fuelType: [],
    modelRange: '',
    group: '',
    hullMaterial: [],
    engine: [],
    engineOneHours: {},
    pageSize: '28',
    page: '1'
  };

  const DEFAULT_REFINE_BRANDED_SEARCH = {
    radius: '0',
    make: '',
    length: {},
    model: '',
    pageSize: '26',
    page: '1'
  };

  const allTypes = ['power-all', 'sail-all', 'unpowered-all'];

  const DEFAULT_MIN_YEAR = 1970;
  const DEFAULT_MAX_YEAR = new Date().getFullYear() + 1;

  const US_STATES = new states().format({
    $value: 'abbr',
    $name: 'name'
  });

  const US_COUNTRIES = [
    {
      value: 'US',
      name: 'United States'
    }
  ];

  const FUEL_TYPE_MAPPING = {
    api: {
      gas: 'gasoline'
    },
    urlId: {
      gas: 'gasoline'
    }
  };

  const HULL_MATERIAL_MAPPING = {
    api: {
      composite: 'fiberglass-composite',
      fiberglass: 'fiberglass-reinforced'
    },
    urlId: {
      'fiberglass-composite': 'composite',
      'fiberglass-reinforced': 'fiberglass'
    },
    value: {
      pvc: 'plastic'
    }
  };

  const GROUPS_CLASSES_MAPPING = {
    'pleasure-boats': {
      type: 'power',
      classes:
        'power-aft+power-bay+power-bowrider+power-pcatamaran+power-cruiser+power-cuddy+power-dive+power-downeast+power-skifish+power-runabout+power-walk'
    },
    'fishing-boats': {
      type: 'power',
      classes: 'power-saltfish+power-fresh'
    },
    angler: {
      type: 'power',
      classes: 'power-saltfish'
    },
    'duck-boat': {
      type: 'power',
      classes: 'power-jon'
    },
    weekender: {
      type: 'power',
      classes: 'power-cruiser'
    },
    submersible: {
      type: 'power',
      classes: 'power-submersible'
    }
  };

  const DEFAULT_NO_MAX_LENGTH = '101';
  const DEFAULT_NO_MAX_PRICE = '100000';
  const DEFAULT_NO_MAX_POWER_HP = '500';
  const SLIDER_MAX_VALUE = 'MAX';

  const MAX_MAKE_COUNT = 10;
  const MAX_MODEL_COUNT = 10;
  const MAX_COUNTRIES_COUNT = 10;
  const MAX_SREGIONS_COUNT = 10;
  const MAX_SUBDIVISIONS_COUNT = 10;
  const MAX_CITIES_COUNT = 10;

  const INDEXABLE_FACETS = [
    'multiFacetedBoatTypeClass',
    'makeModel',
    'modelRange',
    'subdivision',
    'region',
    'country',
    'city',
    'page',
    'condition',
    'hullMaterial',
    'fuelType',
    'group',
    'worldRegion'
  ];

  const ENGINES_INDEXABLE_FACETS = [
    'condition',
    'category',
    'country',
    'fuel',
    'makeModel',
    'page'
  ];

  const BRANDED_INDEXABLE_FACETS = [
    'multiFacetedBoatTypeClass',
    'owner',
    'page'
  ];

  const BROKER_SPOTLIGHT_INDEXABLE_FACETS = [
    'multiFacetedBoatTypeClass',
    'owner',
    'rep',
    'page'
  ];

  const INDEXABLE_PARAMS = [
    'types',
    'classes',
    'make',
    'model',
    'modelRange',
    'country',
    'region',
    'subdivision',
    'city',
    'condition',
    'page',
    'owner',
    'rep',
    'fuel',
    'category',
    'fuelType',
    'hullMaterial',
    'group',
    'worldRegion'
  ];

  const NON_CANONICAL_PARAMS = ['page', 'owner'];

  const INDEXABLE_TRANSLATION_PARAMS_PRIORITY = [
    'condition',
    'type',
    'class',
    'group',
    'make',
    'model',
    'modelRange',
    'engineType',
    'sellerType',
    'region',
    'subdivision',
    'country',
    'city',
    'fuelType',
    'hullMaterial'
  ];

  const PAGE_TYPES = {
    boats: 'BOATS',
    engines: 'ENGINES',
    boatDetails: 'BOAT_DETAILS',
    engineDetails: 'ENGINE_DETAILS',
    brandedSearch: 'BRANDED_SEARCH',
    enginesBrandedSearch: 'ENGINES_BRANDED_SEARCH',
    dealerGallery: 'DEALER_GALLERY',
    enginesDealerGallery: 'ENGINES_DEALER_GALLERY',
    homePage: 'HOMEPAGE',
    healthcheck: 'HEALTHCHECK',
    dealerSearch: 'DEALERSEARCH',
    blog: 'BLOG',
    brandedOemSearch: 'BRANDED_OEM_SEARCH',
    typeClass: 'TYPE_CLASS',
    class: 'CLASS',
  };

  const IGNORED_SUBDOMAINS = ['qa', 'dev', 'prod'];

  // Order of URL_PATTERNS is important. If multiple pattern matches, we take the first one.
  const URL_PATTERNS = [
    {
      type: 'boatDetails',
      pattern: config.BOAT_DETAILS_URL_PATTERN
    },
    {
      type: 'engineDetails',
      pattern: config.ENGINE_DETAILS_URL_PATTERN
    },
    {
      type: 'blog',
      pattern: BLOG_URL_PATTERN
    },
    {
      type: 'boats',
      pattern: SEARCH_URL_PATTERN
    },
    {
      type: 'brandedSearch',
      pattern: BRANDED_SEARCH_URL_PATTERN
    },
    {
      type: 'dealerGallery',
      pattern: DEALER_GALLERY_URL_PATTERN
    },
    {
      type: 'class',
      pattern: config.CLASS_PAGE_URL_PATTERN
    },
    {
      type: 'typeClass',
      pattern: config.TYPE_CLASS_PAGES_ROOT
    },
    {
      type: 'dealerSearch',
      pattern: config.PARTY_SEARCH_URL_ROOT
    },
    {
      type: 'brandedOemSearch',
      pattern: BRANDED_OEM_SEARCH_URL_PATTERN
    },
    {
      type: 'engines',
      pattern: ENGINES_SEARCH_URL_PATTERN
    },
    {
      type: 'enginesBrandedSearch',
      pattern: ENGINES_BRANDED_SEARCH_URL_PATTERN
    },
    {
      type: 'enginesDealerGallery',
      pattern: ENGINES_DEALER_GALLERY_URL_PATTERN
    },
    {
      type: 'healthcheck',
      pattern: config.HEALTHCHECK_URL_PATTERN
    },
    {
      type: 'homepage',
      pattern: config.HOME_URL_PATTERN
    }
  ];

  const checkDealerTypePath = (path, t) => {
    let isOwnerPath = false;
    let isDealerPath = false;
    let isEnginesDealerPath = false;
    const messages = getMessages();
    if (typeof t === 'function') {
      const ownerRegex = new RegExp(t(messages.search.owner) + '-', 'i');
      const dealerRegex = new RegExp(
        t(messages.dealerSearchRoot) + '/' + t(messages.dealerPrefix) + '-',
        'i'
      );
      const enginesDealerRegex = new RegExp(
        t(messages.dealerEnginesSearchRoot) +
          '/' +
          t(messages.dealerPrefix) +
          '-',
        'i'
      );
      isOwnerPath = path?.search(ownerRegex) > -1;
      isDealerPath = path?.search(dealerRegex) > -1;
      isEnginesDealerPath = path?.search(enginesDealerRegex) > -1;
    }
    return { isOwnerPath, isDealerPath, isEnginesDealerPath };
  };

  const getPageTypeFromPath = (path, t, urlPatterns) => {
    urlPatterns = urlPatterns || URL_PATTERNS;
    const matches = urlPatterns.filter((urlPattern) =>
      matchPath(decodeURIComponent(path), urlPattern.pattern)
    );

    if (matches.length === 0) {
      return null;
    }

    // Sometimes more than one pattern matches, that's why the order of URL_PATTERNS matters, we take the first one.
    let pageType = PAGE_TYPES[matches[0].type];

    const hasManyMatches = matches.length > 1;

    if (hasManyMatches) {
      const { isOwnerPath, isDealerPath, isEnginesDealerPath } =
        checkDealerTypePath(path, t);

      if (isDealerPath) {
        pageType = PAGE_TYPES.dealerGallery;
      } else if (isEnginesDealerPath) {
        pageType = PAGE_TYPES.enginesDealerGallery;
      } else if (isOwnerPath) {
        pageType = PAGE_TYPES.brandedSearch;
      }
    }

    return pageType || null;
  };

  return {
    KEYWORD_SEARCH_PATTERN,
    LISTING_ENHANCED,
    LISTING_MANUFACTURER,
    LISTING_MULTI_CONTACT,
    LISTING_OEM_BRAND,
    LISTING_PREMIUM_BROKER,
    LISTING_RESIZES,
    FIFTH_ROW_POSITION,
    LISTING_SAME_DEALER,
    LISTING_SIMILAR_LISTING,
    LISTING_SPONSORED,
    LISTING_FEATURED,
    LISTING_STANDARD,
    LISTING_SUGGESTED,
    SEARCH_TYPES,
    LOCATION_FILTERS_CONFIG,
    _searchParamsPatterns,
    IGNORED_SUBDOMAINS,
    SEARCH_URL_PATTERN,
    ENGINES_SEARCH_URL_PATTERN,
    BRANDED_SEARCH_URL_PATTERN,
    BRANDED_OEM_SEARCH_URL_PATTERN,
    DEALER_GALLERY_URL_PATTERN,
    ENGINES_BRANDED_SEARCH_URL_PATTERN,
    ENGINES_DEALER_GALLERY_URL_PATTERN,
    GENERATE_DEALER_GALLERY_URL_PATTERN,
    GENERATE_ENGINES_DEALER_GALLERY_URL_PATTERN,
    SEARCH_URL_CATEGORY_PATTERN,
    SEARCH_URL_TYPE_PATTERN,
    SEARCH_URL_CLASS_PATTERN,
    SEARCH_URL_CLASS_GROUP_PATTERN,
    SEARCH_URL_ENGINE_NUMBER_PATTERN,
    SEARCH_URL_MAKE_PATTERN,
    SEARCH_URL_MODEL_PATTERN,
    SEARCH_URL_MODELRANGE_PATTERN,
    SEARCH_URL_SUBDIVISION_PATTERN,
    SEARCH_URL_SUBDIVISION_US_PATTERN,
    SEARCH_URL_SUBDIVISION_CA_PATTERN,
    SEARCH_URL_SUBDIVISION_ES_PATTERN,
    SEARCH_URL_SUBDIVISION_IT_PATTERN,
    SEARCH_URL_REGION_PATTERN,
    SEARCH_URL_COUNTRY_PATTERN,
    SEARCH_URL_CITY_PATTERN,
    SEARCH_URL_POSTALCODE_PATTERN,
    SEARCH_URL_BY_PATTERN,
    SEARCH_URL_RADIUS_PATTERN,
    SEARCH_URL_MAKE_MODEL_PATTERN,
    SEARCH_URL_FUEL_PATTERN,
    SEARCH_URL_HULL_PATTERN,
    SEARCH_URL_WORLD_REGION_PATTERN,
    SEARCH_URL_ENGINETYPE_PATTERN,
    SEARCH_URL_ENGINE_HOURS_PATTERN,
    SEARCH_URL_LENGTH_PATTERN,
    SEARCH_URL_PRICE_PATTERN,
    SEARCH_URL_POWER_HP_PATTERN,
    SEARCH_URL_YEAR_PATTERN,
    SEARCH_URL_CONDITION_PATTERN,
    SEARCH_URL_PAGE_PATTERN,
    SEARCH_URL_SORT_PATTERN,
    SEARCH_URL_MODAL_PATTERN,
    SRP_MAXIMUM_ENGINES_NUMBER_SEARCH,
    BRANDED_SEARCH_URL_OWNER_PATTERN,
    BRANDED_SEARCH_URL_REP_PATTERN,
    SEARCH_URL_KEYWORD_PATTERN,
    DEFAULT_REFINE_SEARCH,
    DEFAULT_REFINE_BRANDED_SEARCH,
    BLOG_URL_PATTERN,
    BLOG_URL_CATEGORY_PATTERN,
    BLOG_URL_ARTICLE_PATTERN,
    allTypes,
    DEFAULT_MIN_YEAR,
    DEFAULT_MAX_YEAR,
    US_COUNTRIES,
    FUEL_TYPE_MAPPING,
    HULL_MATERIAL_MAPPING,
    GROUPS_CLASSES_MAPPING,
    DEFAULT_NO_MAX_LENGTH,
    DEFAULT_NO_MAX_PRICE,
    DEFAULT_NO_MAX_POWER_HP,
    SLIDER_MAX_VALUE,
    MAX_MAKE_COUNT,
    MAX_MODEL_COUNT,
    MAX_COUNTRIES_COUNT,
    MAX_SREGIONS_COUNT,
    MAX_SUBDIVISIONS_COUNT,
    MAX_CITIES_COUNT,
    INDEXABLE_FACETS,
    ENGINES_INDEXABLE_FACETS,
    BRANDED_INDEXABLE_FACETS,
    BROKER_SPOTLIGHT_INDEXABLE_FACETS,
    INDEXABLE_PARAMS,
    NON_CANONICAL_PARAMS,
    INDEXABLE_TRANSLATION_PARAMS_PRIORITY,
    PAGE_TYPES,
    URL_PATTERNS,
    getPageTypeFromPath,
    GENERATE_SEARCH_URL_PATTERN,
    GENERATE_BRANDED_OEM_SEARCH_URL_PATTERN,
    GENERATE_ENGINES_SEARCH_URL_PATTERN,
    GENERATE_BRANDED_SEARCH_URL_PATTERN,
    GENERATE_ENGINES_BRANDED_SEARCH_URL_PATTERN,
    ALLOWED_CHARACTERS_IN_URL,
    ALLOWED_CHARACTERS_IN_BLOG_CATEGORY_URL,
    _generateSearchParamsPatterns,
    US_STATES
  };
};

export const TAG_ATTRIBUTES = [
  'AVAILABLE_SOON',
  'IN_STOCK',
  'SALE_PENDING',
  'NEW_ARRIVAL'
];
