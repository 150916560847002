import { prepareBoatsData } from '../utils/requestHelpers';
import * as types from '../store/constants';
import * as httpClient from '../utils/httpClient';

export const fetchBoats = async (fetchBoatQuery, requestContext, http) => {
  try {
    const headers = requestContext?.headers;
    if (http && headers) {
      headers['x-type-portal'] = types.HEADER_PORTAL_TYPE;
    }
    const requestData = { params: fetchBoatQuery };
    const apiClient = httpClient.getHttpClient(headers);
    const boatsResponse = await apiClient.get('/search/boat', requestData);
    const boatsResult = prepareBoatsData(boatsResponse);
    return { boatsResult, error: null };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('getBoats error', err);
    return { boatsResult: null, error: err };
  }
};
