import * as types from '../constants';
import get from 'lodash/get';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import { getPartiesHelperDefaults } from '../../utils/partiesHelper';

const startUser = { userName: '', loggedIn: false };
export const getReducersInitialState = () => {
  const initialState = {
    isWorking: false,
    componentWorking: [],
    success: false,
    errors: false,
    message: '',
    params: {},
    data: {},
    defaultStaticRouting: {
      match: false,
      error: false,
      data: null,
    },
    user: startUser,
    monthlyPrice: '',
    carouselClickCount: 0,
    myboats: {
      requireLogin: true
    },
    parties: {
      search: {
        loading: false,
        params: getPartiesHelperDefaults(),
        autocomplete: {
          text: '',
          locations: []
        }
      }
    },
    config: {
      geo: {
        region: []
      }
    },
    trident: {},
    typeClass: {
      userAgent: ''
    },
    currency: ''
  };
  return initialState;
};

const reducer = (state, action) => {
  if (!state) {
    state = getReducersInitialState();
  }
  switch (action.type) {
    case types.GET_DATA_REQUEST:
      return {
        ...state,
        ...omit(action, 'type'),
        isWorking: true,
        params: action.params || {}
      };

    case types.GET_DATA_LOADING:
      // we can call it more than once
      if (state.isWorking) {
        return state;
      }

      return {
        ...state,
        ...omit(action, 'type'),
        isWorking: true
      };

    case types.GET_DATA_SUCCESS:
      return {
        ...state,
        ...omit(action, 'type'),
        data: {
          ...state.data?.adsData && { adsData: { ...state.data.adsData } },
          ...action.data
        }
      };
    case types.GET_FACET_REQUEST:
      return {
        ...state,
        ...omit(action, 'type'),
        componentWorking: [...state.componentWorking, 'facets'],
        params: action.params || {}
      };
    case types.GET_FACET_SUCCESS:
      return {
        ...state,
        ...omit(action, 'type'),
        componentWorking: filter(
          state.componentWorking,
          (item) => item !== 'facets'
        ),
        isWorking: false,
        data: {
          ...state.data,
          facets: action.data.facets || []
        }
      };

    case types.GET_SORT_PARAM:
      return {
        ...state,
        sortBy: action.sortBy,
        ...omit(action, 'type')
      };

    case types.GET_DATA_FAILURE:
      return {
        ...state,
        ...omit(action, 'type')
      };

    case types.LOGIN_USER:
      return {
        ...state,
        user: {
          userName: action.user.userName,
          loggedIn: true
        },
        myboats: {
          requireLogin: false,
          listings: get(state, 'myboats.listings', [])
        }
      };

    case types.LOGOUT_USER:
      return {
        ...state,
        user: startUser,
        myboats: { requireLogin: true, listings: [] }
      };

    case types.MYBOATS_LISTINGS_SUCCESS:
      return {
        ...state,
        myboats: { requireLogin: false, listings: action.myboats }
      };

    case types.MYBOATS_LISTINGS_FAILURE:
      return {
        ...state,
        myboats: { requireLogin: false, listings: [] }
      };

    case types.MYBOATS_REQUIRE_LOGIN:
      return {
        ...state,
        myboats: { requireLogin: true, listings: [] }
      };

    case types.INCREMENT_CAROUSEL_CLICKCOUNT:
      return {
        ...state,
        ...omit(action, 'type'),
        carouselClickCount: state.carouselClickCount + 1
      };

    case types.NEXT_PREVIOUS_DATA:
      return {
        ...state,
        navLinks: action.data
      };

    case types.NEXT_PREVIOUS_ENGINES_DATA:
      return {
        ...state,
        navLinks: action.data
      };

    case types.SET_IS_WORKING:
      return {
        ...state,
        isWorking: Boolean(action.data)
      };

    case types.SET_PARTY_RESULTS: {
      const { search = {}, seoContent = [] } = action.data;
      const count = search.count || 0;
      const records = search.records || [];
      return {
        ...state,
        parties: {
          ...state.parties,
          search: {
            ...state.parties.search,
            error: false,
            count,
            records
          }
        },
        data: {
          ...state.data,
          seoContent
        }
      };
    }

    case types.SET_PARTY_SEARCH_PARAMS:
      return {
        ...state,
        parties: {
          ...state.parties,
          search: {
            ...state.parties.search,
            params: action.data
          }
        }
      };

    case types.SET_PARTY_RESULTS_ERROR:
      return {
        ...state,
        parties: {
          ...state.parties,
          search: {
            ...state.parties.search,
            error: true
          }
        }
      };

    case types.SET_PARTY_SEARCH_AUTO_SUGGESTIONS:
      return {
        ...state,
        parties: {
          ...state.parties,
          search: {
            ...state.parties.search,
            autocomplete: {
              ...state.parties.search.autocomplete,
              locations: action.data
            }
          }
        }
      };

    case types.SET_MAKEMODEL_SEARCH_AUTO_SUGGESTIONS:
      return {
        ...state,
        makeModel: {
          makeModels: action.data
        }
      };

    case types.SET_PARTY_SEARCH_AUTO_TEXT:
      return {
        ...state,
        parties: {
          ...state.parties,
          search: {
            ...state.parties.search,
            autocomplete: {
              ...state.parties.search.autocomplete,
              text: action.data
            }
          }
        }
      };

    case types.SET_CONFIG_GEO_REGION:
      return {
        ...state,
        config: {
          ...state.config,
          geo: {
            ...state.config.geo,
            region: action.data
          }
        }
      };

    case types.SET_ADDITIONAL_LEADS_DATA:
      return {
        ...state,
        additionalLeads: {
          ...state.additionalLeads,
          leadData: action.data.leadData,
          listings: action.data.listings
        }
      };

    case types.TOGGLE_ADDITIONAL_LEADS_MODAL:
      return {
        ...state,
        additionalLeads: {
          ...state.additionalLeads,
          open: action.data
        }
      };

    case types.TRIDENT_SET_RATES:
      return {
        ...state,
        trident: {
          ...state.trident,
          rates: action.data
        }
      };

    case types.TRIDENT_SET_BOAT_LOANS_CONTENT:
      return {
        ...state,
        trident: {
          ...state.trident,
          boatLoansSection: action.data
        }
      };

    case types.TRIDENT_LEAD_SUBMITTED:
      return {
        ...state,
        trident: {
          ...state.trident,
          isLeadSubmitted: action.data
        }
      };

    case types.SET_USER_AGENT:
      return {
        ...state,
        typeClass: {
          ...state.typeClass,
          userAgent: action.data
        }
      };

    case types.SET_ADS_DATA: {
      return {
        ...state,
        ...omit(action, 'type'),
        data: {
          ...state.data,
          adsData: action.data
        }
      };
    }
    case types.SET_SRP_CURRENCY:
      return {
        ...state,
        currency: action.data
      };


    case types.GET_STATIC_ROUTING_SUCCESS: {
      return {
        ...state,
        defaultStaticRouting: {
          match: false,
          error: false,
          data: action.data
        }
      };
    }
    case types.GET_STATIC_ROUTING_FAILURE: {
      return {
        ...state,
        defaultStaticRouting: {
          match: false,
          error: true,
          data: null
        }
      };
    }
    case types.SET_STATIC_ROUTING_MATCH: {
      return {
        ...state,
        defaultStaticRouting: {
          ...state.defaultStaticRouting,
          match: action.data
        }
      };
    }

    default:
      return state;
  }
};

export default reducer;
