import React from 'react';
import PropTypes from 'prop-types';
export const RequestContext = React.createContext();

export const ServiceContext = React.createContext();

export const ServicesProvider = ({ children, tpp, req}) => {
  return (
    <ServiceContext.Provider value={tpp}>
      <RequestContext.Provider value={req}>
        {children}
      </RequestContext.Provider>
    </ServiceContext.Provider>
  );
};

const RequestConsumer = RequestContext.Consumer;

export const withRequestContext = (Component) => {
  const Wrapper = (props) => {
    return (
      <RequestConsumer>
        {(context) => {
          return <Component requestContext={context} {...props} />;
        }}
      </RequestConsumer>
    );
  };

  return Wrapper;
};

ServicesProvider.propTypes = {
  children: PropTypes.node,
  userAgent: PropTypes.string,
  tpp: PropTypes.object,
  req: PropTypes.object
};
