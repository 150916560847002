export const GET_DATA_REQUEST = 'GET_DATA_REQUEST';
export const POST_DATA_REQUEST = 'POST_DATA_REQUEST';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const GET_DATA_LOADING = 'GET_DATA_LOADING';
export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
export const GET_FACET_REQUEST = 'GET_FACET_REQUEST';
export const GET_FACET_SUCCESS = 'GET_FACET_SUCCESS';
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE';
export const POST_DATA_FAILURE = 'POST_DATA_FAILURE';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const MYBOATS_LISTINGS_SUCCESS = 'MYBOATS_LISTINGS_SUCCESS';
export const MYBOATS_LISTINGS_FAILURE = 'MYBOATS_LISTINGS_FAILURE';
export const MYBOATS_REQUIRE_LOGIN = 'MYBOATS_REQUIRE_LOGIN';
export const GET_SORT_PARAM = 'GET_SORT_PARAM';
export const PRICE_PER_MONTH = 'PRICE_PER_MONTH';
export const INCREMENT_CAROUSEL_CLICKCOUNT = 'INCREMENT_CAROUSEL_CLICKCOUNT';
export const NEXT_PREVIOUS_DATA = 'NEXT_PREVIOUS_DATA';
export const NEXT_PREVIOUS_ENGINES_DATA = 'NEXT_PREVIOUS_ENGINES_DATA';
export const SET_MAKEMODEL_SEARCH_AUTO_SUGGESTIONS =
  'SET_MAKEMODEL_SEARCH_AUTO_SUGGESTIONS';
export const SET_PARTY_RESULTS = 'SET_PARTY_RESULTS';
export const SET_PARTY_RESULTS_ERROR = 'SET_PARTY_RESULTS_ERROR';
export const SET_PARTY_SEARCH_PARAMS = 'SET_PARTY_SEARCH_PARAMS';
export const SET_PARTY_SEARCH_AUTO_SUGGESTIONS =
  'SET_PARTY_SEARCH_AUTO_SUGGESTIONS';
export const SET_PARTY_SEARCH_AUTO_TEXT = 'SET_PARTY_SEARCH_AUTO_TEXT';
export const SET_CONFIG_GEO_REGION = 'SET_CONFIG_GEO_REGION';
export const SET_IS_WORKING = 'SET_IS_WORKING';
export const SET_ADDITIONAL_LEADS_DATA = 'SET_ADDITIONAL_LEADS_DATA';
export const TOGGLE_ADDITIONAL_LEADS_MODAL = 'TOGGLE_ADDITIONAL_LEADS_MODAL';
export const TRIDENT_SET_RATES = 'TRIDENT_SET_RATES';
export const TRIDENT_SET_BOAT_LOANS_CONTENT = 'TRIDENT_SET_BOAT_LOANS_CONTENT';
export const TRIDENT_LEAD_SUBMITTED = 'TRIDENT_LEAD_SUBMITTED';
export const HEADER_PORTAL_TYPE = 'portal';
export const SET_USER_AGENT = 'SET_USER_AGENT';
export const SET_ADS_DATA = 'SET_ADS_DATA';
export const SET_SRP_CURRENCY = 'SET_SRP_CURRENCY';
export const GET_STATIC_ROUTING_SUCCESS = 'GET_STATIC_ROUTING_SUCCESS';
export const GET_STATIC_ROUTING_FAILURE = 'GET_STATIC_ROUTING_FAILURE';
export const SET_STATIC_ROUTING_MATCH = 'SET_STATIC_ROUTING_MATCH';
