import {TPPServiceContainer} from '../../tppServices/tppDi';
import {TPP_CONTAINER} from '../../tppServices/crossEnvHelpers';
import {createConfigService} from '../../tppServices/configService';
import { v4 as uuid } from 'uuid';

const getHostFromRequest = (req, config) => {
  const supportsForwardedHost = !!config.supports?.forwardedHost;
  const forwardedHost = req.headers['x-forwarded-host'];
  if (supportsForwardedHost && forwardedHost) {
    return forwardedHost;
  }
  return req.get('host');
};

const requestDataManager = () => {
  let ipAddress, request, userAgent;

  const getIp = () => {
    return /^(\d{1,3}\.){3}\d{1,3}$/g.test(ipAddress) && ipAddress;
  };
  const setIp = (newIpAddress) => {
    ipAddress = newIpAddress || '';
  };

  const getAgent = () => userAgent;
  const setAgent = (newUserAgent) => {
    userAgent = newUserAgent || '';
  };

  const getRequest = () => request;
  const setRequest = (newRequest) => {
    request = newRequest;
  };

  return { getIp, setIp, getAgent, setAgent, getRequest, setRequest };
};

const servicesMiddleware = (config) => {
  return async function (req, res, next) {
    try {
      if (!req.tpp) {
        const configService = await createConfigService(config);
        const host = getHostFromRequest(req, config);
        const tpp = TPPServiceContainer(TPP_CONTAINER);
        let started = tpp.started();
        if (!started) {
          started = await tpp.start(configService, req.originalUrl, `${req.protocol}://${host}`);
        }
        if (started) {
          req.tpp = tpp;
        } else {
          throw new Error('Failed to start TPP in src/server/middleware/translations.js servicesMiddleware');
        }
      }
      next();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('log-tag new servicesMiddleware error', error.message);
      next(error);
    }
  };
};

const initializeRequestData = (req) => {
  // IP address
  const ipAddress = req.headers['x-forwarded-for']?.split(',')[0] || req.ip;
  setIpAddress(ipAddress);

  // User agent
  const userAgent = req.get('User-Agent');
  setUserAgent(userAgent);

  // Request
  setHttpRequest(req);
  return ipAddress;
};

const uuidMiddleware = () => (req, res, next) => {
  // we check here the cached data
  const cachedIpAddress = initializeRequestData(req);
  const guuid = uuid();
  const printableUuid = req.originalUrl + '-' + guuid;
  req.uuid = cachedIpAddress + '-' + printableUuid;
  // we want to print it on the dom
  req.printableUuid = printableUuid;
    // we want to have the uuid in the dom as well
  res.setHeader('X-Request-Id', guuid);
  next();
};

export const { getIp: getIpAddress, setIp: setIpAddress, getAgent: getUserAgent, setAgent: setUserAgent, getRequest: getHttpRequest, setRequest: setHttpRequest } = requestDataManager();

export {servicesMiddleware, getHostFromRequest, uuidMiddleware};
