import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CurrencyPicker } from '@dmm/lib-react-ui-components';
import { PortalConfigContext } from '../../config/portal';
import {
  getPathnameLanguage
} from '../../utils/language';

import {
  getAssetUrl,
  isMobileResolution as getIsMobileResolution,
  isSafelyInitializedWithLayout
} from '../../utils/commonHelper';
import { getCurrentLocale } from '../../utils/language';
import { generateSearchPath, parseSearchParams } from '../../utils/urlHelpers/boats';
import { generateBlogPath } from '../../utils/urlHelpers/blog';
import { generateSearchPath as generateEnginesSearchPath } from '../../utils/urlHelpers/engines';
import { setNavigationEvent } from '../../store/actions/dataLayer';
import { setSRPCurrency } from '../../store/actions';
import LanguagePicker from '../LanguagePicker';
import { getFSBOUrl } from '../../utils/urlHelpers/fsbo';
import './styles.css';

import {useTPPServices} from '../../tppServices/tppDIHooks';
import { getMessages } from '../../tppServices/translations/messages';
import { definedValue, yieldToMain } from '../../utils';
import { SRP_LITERALS, useCurrency } from '../../pages/SearchResults/components/RefineSearch/FilterHelpers/useSRPFilters';
import loadable from '@loadable/component';

const KeywordSearch = loadable(() => import('../KeywordSearch'));

const pagesAllowedShowCurrencyDropdown = ["SearchResults", "BrandedSearch", "BrandedOEMSearchResults"];

const Header = ({location = {}, pageType = '', isWorking }) => {
  const messages = getMessages();
  const [isMobileResolution, setIsMobileResolution] = useState(
    isSafelyInitializedWithLayout('mobile')
  );
  // we need it on server side render
  const [searchboxDesktop, setSearchSearchboxDesktop] = useState(true);

  const [open, setOpen] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isThrottled, setIsThrottled] = useState(false);
  const {routesConstants} = useTPPServices();
  const intl = useIntl();
  const dispatch = useDispatch();

  const context = useContext(PortalConfigContext);
  const supports = context.supports;
  let isUsersCurrencyEnabled = !!supports?.isUsersCurrencyEnabled;
  const isCurrencyDropdownEnabled = isUsersCurrencyEnabled && pagesAllowedShowCurrencyDropdown.includes(pageType);

  const urlParams = parseSearchParams(location?.pathname);
  const { selectedCurrency: userCurrency } = useCurrency(urlParams?.currency);

  const trackGenericClick = (event) => () => {
    if (event) {
      setTimeout(() => {
        dispatch(setNavigationEvent(event));
      });
    }
  };

  const generatePathFromRoute = (route) => {
    const routes = routesConstants;
    if (typeof route === 'object' && route.search) {
      if (route.type === 'engine') {
        return generateEnginesSearchPath({}, route.search, true);
      }

      return generateSearchPath({}, route.search, true);
    }
    if (route && route.article) {
      return `${getPathnameLanguage()}${route.article}`;
    }
    if (route && route.blog) {
      return generateBlogPath(route.blog);
    }
    if (
      route &&
      ['SELL_URL_ROOT', 'SELL_ENGINES_URL_ROOT'].indexOf(route) >= 0
    ) {
      return getFSBOUrl(route);
    }
    return routes[route];
  };

  const getHeaderLinks = (headerContext) => {
    const isTopNavigationV2Enabled = !!context.supports.enableTopNavigationV2;
    if (isTopNavigationV2Enabled) {
      return definedValue(headerContext.linksV2, []);
    }

    return definedValue(headerContext.links, []);
  };

  const toggleOpen = useCallback( async () => {
    setIsThrottled(true);
    await yieldToMain();
    setOpen((open) => !open);
    requestAnimationFrame(() => {
      setIsThrottled(false);
    });
  }, [isPageLoaded, isThrottled]);

  useEffect(() => {
    const handleResize = () => setIsMobileResolution(getIsMobileResolution());
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handlePageLoad = () => {
      setIsPageLoaded(true);
      document.getElementById('hdr-nav-tgl-btn')?.classList.remove('disable-actions');
    };
    document.readyState === 'complete' ? handlePageLoad() : window.addEventListener('load', handlePageLoad);

    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [isPageLoaded]);

  useEffect(() => {
    const hydrationTimer = requestAnimationFrame(() => {
      setSearchSearchboxDesktop(!getIsMobileResolution());
    });

    return () => cancelAnimationFrame(hydrationTimer);
  }, []);

  useEffect(() => {
    setSearchSearchboxDesktop(!isMobileResolution);
  }, [isMobileResolution]);

  const { formatMessage: t } = intl;
  const currentLanguage = getCurrentLocale();
  const multiLanguage = supports?.multiLanguage;
  const fsboCTA = definedValue(supports.fsboCTA, true);
  const headerContext = definedValue(context.header, {});
  const logo = definedValue(context.languages[currentLanguage]?.alternativeAssets?.header?.logo, headerContext.logo);

  const isTopNavigationV2Enabled = !!context.supports?.enableTopNavigationV2;
  const headerLinks = getHeaderLinks(headerContext);
  const sellBoatUrl = getFSBOUrl();
  const usesOldHeader = context.supports?.oldHeader;
  const headerClassnames = useMemo(() => classnames('page-header-container', {
    'new-header': !usesOldHeader,
    'header-v2': isTopNavigationV2Enabled
  }), [context.supports]);
  const toggleClassnames = useMemo(() => classnames('page-header', {
    'nav-open': open
  }), [open, isPageLoaded]);

  const keywordSearchOptions = headerContext.keywordSearchOptions;

  return (
    <div id="hdr-container" className={headerClassnames}>
      <div
        id="hdr-page-hdr"
        className={toggleClassnames}
      >
        <nav id="main-nav">
          <ul>
            {headerLinks.map(
              (
                { name, route, links, event, languages, exceptLanguages },
                index
              ) => {
                if (languages) {
                  if (!languages.includes(currentLanguage)) {
                    return null;
                  }
                }

                if (exceptLanguages) {
                  if (exceptLanguages.includes(currentLanguage)) {
                    return null;
                  }
                }
                const childrenLinks = links?.filter(
                  (link) =>
                    !link.languages || link.languages.includes(currentLanguage)
                );
                const hasChildren = Boolean(
                  childrenLinks && childrenLinks.length
                );
                return (
                  <li key={index} className="has-children">
                    <a
                      href={generatePathFromRoute(route)}
                      onClick={trackGenericClick(event)}
                    >
                      {t(messages.header[name])}
                    </a>
                    {hasChildren && (
                      <ul className="drop-list">
                        {childrenLinks.map(
                          ({ name, route, event, target, rel }, index) => {
                            return (
                              <li key={index}>
                                <a
                                  href={generatePathFromRoute(route)}
                                  onClick={trackGenericClick(event)}
                                  target={target}
                                  rel={rel}
                                >
                                  {t(messages.header[name])}
                                </a>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </li>
                );
              }
            )}
          </ul>
        </nav>
        <header className="main nav-slide">
          <button
            id="hdr-nav-tgl-btn"
            onClick={isPageLoaded && !isThrottled ?
              () => {
                toggleOpen();
              } :
              undefined
            }
            className="navbar-toggle disable-actions"
            disabled={!isPageLoaded || isThrottled}
          >
            <span className="visuallyhidden">{t(messages.header.toggle)}</span>
          </button>
          <a href={routesConstants.HOME_URL_ROOT} className="logo-link">
            <img
              className="logo"
              src={getAssetUrl(logo)}
              alt={context.fullName}
            />
          </a>
          <a href={routesConstants.HOME_URL_ROOT} className="logo-mobile-link">
            <img
              className="logo-mobile"
              src={getAssetUrl(headerContext.logoMobile || logo)}
              alt={context.fullName}
            />
          </a>
          <a href={routesConstants.HOME_URL_ROOT} className="logo-nav-link">
            <img
              className="logo-nav"
              src={getAssetUrl(headerContext.logoNav || logo)}
              alt={context.fullName}
            />
          </a>
        </header>
        <div className="right-nav">
          {fsboCTA && (
            <div className="cta-wrapper">
              <a
                id="nav-fsbo-cta"
                className="nav-cta"
                href={sellBoatUrl}
                onClick={trackGenericClick({
                  action: 'sell your boat',
                  label: 'sell your boat'
                })}
              >
                {'\uFF0B ' + t(messages.header.callToAction)}
              </a>
            </div>
          )}
          {multiLanguage && <LanguagePicker />}
          { searchboxDesktop && isCurrencyDropdownEnabled && (
            <div className="currency-picker" >
              <CurrencyPicker
                selected={ userCurrency }
                onChange={(e, value) => {
                  dispatch(setSRPCurrency(value));
                }}
                items={ SRP_LITERALS.CURRENCIES }
              />
            </div>
          ) }
          <div className="header-login">
            <div className="header-login-icon">
              <div className="header-login-head"></div>
              <div className="header-login-body"></div>
            </div>
            <div>Login</div>
          </div>
          {searchboxDesktop && headerContext.keywordSearch && (
            <div className={
              classnames('keyword-container-desktop', {
                'keyword-container-circle': isCurrencyDropdownEnabled
              })
            }>
              {' '}
              <KeywordSearch
                intl={intl}
                keywordSearchOptions={keywordSearchOptions}
                isWorking={isWorking}
              />{' '}
            </div>
          )}
        </div>
        {isMobileResolution && headerContext.keywordSearch && (
          <div className="keyword-container-mobile">
            {' '}
            <KeywordSearch
              intl={intl}
              keywordSearchOptions={keywordSearchOptions}
              isWorking={isWorking}
            />{' '}
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  appBannerHeight: PropTypes.number,
  isWorking: PropTypes.bool,
  location: PropTypes.object,
  pageType: PropTypes.string
};

export default Header;
