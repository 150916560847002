import React, {useEffect, useState} from 'react';
import {RawIntlProvider} from 'react-intl';
import PropTypes from 'prop-types';
import {getConfig, PortalConfigContext} from '../config/portal';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import {withContext} from '../context';
import {countries, languages, languageCodes, localeTranslations,} from '../tppServices/translations/languageConstants';
import {getDefaultI18Service, getTPPServices, temporaryIntlContainer} from '../tppServices/tppDi';
import {tempIntlContainers} from '../tppServices/translations/intlManager';
import {useTPPServices} from '../tppServices/tppDIHooks';
import {getTheCurrentPageType} from '../tppServices/translations/helpers';
import {nil} from './runOnceHelper';
import {ServicesProvider} from '../context/ServicesProvider';
import * as utils from '../store/utils';
import { getMessages } from '../tppServices/translations/messages';

export const dealerParts = (str) => {
  const pattern = /^([^-]+)(?:-(.*?))?-([^-]+)$/;
  const match = str.match(pattern);
  if (match && match[2]) {
    const dealerWord = match[1];
    const dealerName = match[2];
    const dealerId = match[3];
    if (!isNaN(dealerId)) {
      return [dealerWord, dealerName, parseInt(dealerId, 10)];
    }
  }
  return null;
};

export const translateDealer = (dealer, lang, formatMessage, isBroker) => {
  const parts = dealerParts(dealer);
  const messages = getMessages();
  if (parts) {
    // eslint-disable-next-line no-unused-vars
    let [_, dealerName, dealerId] = parts;
    const translated = !isBroker
      ? formatMessage(messages.dealerPrefix)
      : formatMessage(messages.brandedSearchBroker);
    return `${translated}-${dealerName}-${dealerId}`;
  }
  return '';
};

export const isLanguageSupportByPortal = (language) => !!getConfig().languages[language];

const prepareLocations = (tpp, pathname) => {
  const languageService = tpp.languageService();
  const configService = tpp.configService();
  const config = configService.getConfig();
  const supportsBlogHome = Object.keys(config.languages).find(anyLang => config.languages[anyLang].supportsBlogHome);  //config.languages[newLangCode].supportsBlogHome;
  const langIntl = languageService.getI18n();
  const pageType = getTheCurrentPageType(pathname, langIntl, supportsBlogHome);
  const langsConfigs = config.languages;
  let localizedLocations = [];
  const supportsMultilanguage = config.supports.multiLanguage;
  return { langsConfigs, localizedLocations, supportsMultilanguage, pageType };
};

export const geti18nLocations = async (tpp, pathname, isForSEOHead = false) => {
  let { langsConfigs, localizedLocations, supportsMultilanguage, pageType} = prepareLocations(tpp, pathname, isForSEOHead);
  if (!supportsMultilanguage) {
    return;
  }
  if (pageType === 'BLOG') {
    if (isForSEOHead) {
      return [];
    }
  }
  for (let lang in langsConfigs) {
    const fullRoute = await tpp.translateUrl(lang, pathname);
    localizedLocations.push({
      lang: lang,
      route: fullRoute,
      tld: langsConfigs[lang].tld
    });
  }
  return localizedLocations;
};

/**
 * This function translates locations sync. It means it needs all the language loaded
 * or it will throw
 * @param tpp
 * @param pathname
 * @param isForSEOHead
 * @returns {*[]}
 * @throws Error
 */
export const geti18nLocationsSync = (tpp, pathname, isForSEOHead) => {
  let { langsConfigs, localizedLocations, supportsMultilanguage, pageType } = prepareLocations(tpp, pathname, isForSEOHead);
  if (!supportsMultilanguage) {
    return;
  }
  if (pageType === 'BLOG') {
    if (isForSEOHead) {
      return [];
    }
  }
  for (let lang in langsConfigs) {
    const fullRoute = tpp.translateUrlSync(lang, pathname);
    localizedLocations.push({
      lang: lang,
      route: fullRoute,
      tld: langsConfigs[lang].tld
    });
  }
  return localizedLocations;
};

export const getTranslatedLocationsSync = (location) => {
  // We can only translate sync server side cause server side we have all languages loaded
  if (utils.isServer()) {
    const {tpp} = getTPPServices();
    const path = location.pathname;
    const currentLocation = path.endsWith('/') ? path : path + '/';
    return geti18nLocationsSync(tpp, currentLocation, true);
  }
  // client side we do not care, only needed for SEO, and SEO relies on
  // server side rendering
  return [];
};


export const LanguageContext = React.createContext();

export const withLanguageContext = withContext(
  LanguageContext,
  'languageContext'
);

export const getPathnameLanguage = (i18Service) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  return i18Service.getCurrentI18n().addPathnameLocale;
};


export const getLanguage = (lang) => languages[lang];

export const getCountry = (country) => countries[country];

export const getSupportedLanguages = (config) => {
  // TODO: when (if) async, remove this call to getConfig
  if (!config) {
    config = getConfig();
  }
  return Object.keys(config.languages);
};

export const getLanguageCode = (lang) => {
  const language = getLanguage(lang);
  return languageCodes[language];
};

const currentConfigLangLocale = (locale, config, getLanguageCode) => {
  const currentCountryCode = locale;
  const currentLocale = findKey(
    localeTranslations,
    (countryCode) => countryCode === currentCountryCode
  );
  const currentLocaleLangConfig = get(
    config.languages,
    currentCountryCode,
    {}
  );
  const currentLocaleCountry = get(currentLocaleLangConfig, 'country', '');
  return getLanguageCode && currentLocaleCountry
    ? currentLocale
    : currentCountryCode;
};

export const getCurrentLocale = (getLanguageCode = false) => {
  const {languageService, configService} = getTPPServices();
  const locale = languageService.getCurrentLocale();
  if (!getLanguageCode && locale) {
    return locale;
  }
  const config = configService.getConfig();
  return currentConfigLangLocale(locale, config, getLanguageCode);
};

export const getApiLocale = () => {
  // TODO: when (if) async, remove this call to getConfig
  const languages = get(getConfig(), 'languages');
  const lang = getCurrentLocale();
  return get(languages, `${lang}.apiLocale`) || getCurrentLocale(true);
};

export const getPortalSeparator = () => {
  // TODO: when (if) async, remove this call to getConfig
  return get(getConfig(), 'separator', '|');
};

export const getCurrentCurrency = (customCurrencyCode = '') => {
  // TODO: remove call when async
  const config = getConfig();
  const currentLocale = getCurrentLocale();
  const formatingOptions = get(
    config,
    `languages.${currentLocale}.currency.format`
  );
  const currencyCode =
    customCurrencyCode ||
    get(config, `languages.${currentLocale}.currency.abbr`);
  const currencyInfo = get(config, `supportedCurrencies.${currencyCode?.toUpperCase()}`);
  return currencyInfo
    ? { 'currency': currencyInfo, 'formatingOptions': formatingOptions }
    : undefined;
};

export const getCurrentUom = () => {
  // TODO: when (if) async, remove this call to getConfig
  const config = getConfig();
  const currentLocale = getCurrentLocale();
  const currentUomInfo = config?.languages?.[currentLocale]?.uom;
  return currentUomInfo;
};

const getTempIntl = (overrideLocale) => {
  // TODO: review memory consumed??
  if (tempIntlContainers[overrideLocale]) {
    return tempIntlContainers[overrideLocale];
  }
  const tempIntl = temporaryIntlContainer(overrideLocale);
  return tempIntl;
};

export const getOverridableRoutes = (overrideLocale, i18Service) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  if (overrideLocale) {
    // this will throw if language does not exist.
    // It is the expected (and required) behavior to avoid a greater refactor
    const tempIntl = getTempIntl(overrideLocale);
    return tempIntl.routes;
  }
  return i18Service.getCurrentI18n().routesConstants;
};

export const getOverridableBoats = (overrideLocale, i18Service) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  if (overrideLocale) {
    // this will throw if language does not exist. It is the expected (and required) behavior to avoid a greater refactor
    const tempIntl = getTempIntl(overrideLocale);
    return tempIntl.boats;
  }
  return i18Service.getCurrentI18n().boatsConstants;
};

export const getPortalName = (context) => {
  const locale = getCurrentLocale();
  return get(context, `languages.${locale}.portal`, context.fullName);
};

// TODO: move all these functions to a proper helper file
export const getConfigParamForLanguage = (parameter, defaultValue) => {
  const language = getCurrentLocale();
  // TODO: when (if) async, remove this call to getConfig
  const cfg = getConfig();
  const cfgLangs = cfg.languages[language];
  const cfgParam = get(cfgLangs, parameter, defaultValue);
  return cfgParam;
};

export const CustomIntlProvider = ({location, host, children}) => {
  const {languageService, currentI18n} = useTPPServices();
  const [I18n, setI18n] = useState(currentI18n);
  useEffect(() => {
    const updateI18n = async () => {
      const newI18n = await languageService.updateI18nByLocation(location, host);
      if (newI18n !== I18n) {
        setI18n(newI18n);
      }
    };
    if (location) {
      updateI18n().then(nil);
    }
  }, [location, host]);
  return (
    <LanguageContext.Provider value={I18n}>
      <RawIntlProvider value={I18n.intl}>{children}</RawIntlProvider>
    </LanguageContext.Provider>
  );
};

CustomIntlProvider.propTypes = {
  children: PropTypes.node,
  pathname: PropTypes.string,
  location: PropTypes.object,
  host: PropTypes.string,
  i18Service: PropTypes.object,
  languageService: PropTypes.shape({
    updateI18nByLocation: PropTypes.func,
    getI18n: PropTypes.func
  }),
};

// Used for tests
// eslint-disable-next-line react/prop-types
export const TPPCustomIntlProvider = ({ children, customServices, location, host }) => {
  const { tpp } = customServices || getTPPServices();

  return (
    <ServicesProvider userAgent={navigator.userAgent} tpp={tpp}>
      <PortalConfigContext.Provider value={tpp.configService().getConfig()}>
        <CustomIntlProvider location={location} host={host}>
          {children}
        </CustomIntlProvider>
      </PortalConfigContext.Provider>
    </ServicesProvider>
  );
};

