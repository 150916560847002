import React, { useContext, memo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PortalConfigContext } from '../../config/portal';
import PropTypes from 'prop-types';

import { isServer } from '../../store/utils';
import { getAssetUrl } from '../../utils/commonHelper';
import { setGenericEvent } from '../../store/actions/dataLayer';
import get from 'lodash/get';
import classnames from 'classnames';
import EditorialItem from '../EditorialItem';
import { editContentAnchors } from '../../pages/Home/utils/utils';
import { getSeoDataItems } from '../../utils/seo';
import NewsletterSubscription from './components/Newsletter';

import './styles.css';
import { getCurrentLocale } from '../../utils/language';
import { getMessages } from '../../tppServices/translations/messages';
import isEqual from 'lodash/isEqual';

const Footer = (props) => {
  const context = useContext(PortalConfigContext);
  const messages = getMessages();
  const intl = useIntl();
  const { formatMessage: t } = intl;

  const dispatch = useDispatch();
  const seoContent = useSelector(state => state?.app?.data?.seoContent || []);
  const trackGenericClick = (event) => () => {
    if (event) {
      dispatch(setGenericEvent('navigation - footer', event.action, event.label));
    }
  };

  const parseEditorialItem = (content) => {
    return editContentAnchors(content, context.pages.home.seoConfig);
  };

  const getSEOContent = (items) =>
    items.length > 0 ? (
      <div className={classnames('footer-links')}>
        <div className={classnames('footer-links-container')}>
          {items.map((list, index) => {
            return (
              <EditorialItem
                header={list.title}
                content={parseEditorialItem(list.html)}
                key={index}
                classOverride={'quicklink-list'}
              />
            );
          })}
        </div>
      </div>
    ) : null;

  const seoListItems = getSeoDataItems(seoContent, 'footer-links');
  const footerContext = context.footer;
  const supports = get(context, 'supports');
  const supportsCmp = get(supports, 'cmp', false);
  const showUpperFooter = get(supports, 'showUpperFooter', false) && props.showUpperFooter;
  const newsletterSubscription = get(supports, 'newsletterSubscription', true);

  return (
    <footer className="main-footer">
      {getSEOContent(seoListItems)}
      {showUpperFooter && (
        <div className="upper-footer">
          <div id="upr-ftr-inr" className="inner">
            {footerContext.subFooter.links.map(({ props, name, event }) => {
              const { href, ...newProps } = props;
              newProps.href = get(messages, `footer.subFooter.${href}`)
                ? t(get(messages, `footer.subFooter.${href}`))
                : href;
              return (
                <a key={name} {...newProps} onClick={trackGenericClick(event)}>
                  {t(get(messages, `footer.subFooter.${name}`))}
                </a>
              );
            })}
            <div className="socials-footer">
              {footerContext.subFooter.socialLinks.map(
                ({ props, name, event, img, alt }) => {
                  const { href, ...newProps } = props;
                  newProps.href = get(messages, `footer.socialLinks.${href}`)
                    ? t(get(messages, `footer.socialLinks.${href}`))
                    : href;
                  return (
                    <a
                      key={name}
                      {...newProps}
                      onClick={trackGenericClick(event)}
                      className={`social-link-${event.label}`}
                    >
                      <LazyLoadImage src={getAssetUrl(img)} alt={alt} />
                    </a>
                  );
                }
              )}
            </div>
          </div>
        </div>
      )}
      {newsletterSubscription ? (
        <NewsletterSubscription intl={intl} />
      ) : null}
      <hr />
      <nav className="footer-disclaimers">
        <div className="footer-top">
          <div className="copyright-info">
            <FormattedMessage
              values={{ date: new Date().getFullYear() }}
              {...messages.footer.copyright}
            />
            {t(messages.footer.boatsGroup).trim().length > 0 && (
              <a
                href={footerContext.copyright.url}
                title={t(messages.footer.boatsGroup)}
                onClick={trackGenericClick(footerContext.copyright.event)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(messages.footer.boatsGroup)}
              </a>
            )}
            {t(messages.footer.allRightsReserved).trim().length > 0 &&
              ` - ${t(messages.footer.allRightsReserved)}`}
            {t(messages.footer.endorsedBranding).trim().length > 0 &&
              ` | ${t(messages.footer.endorsedBranding)}`}
          </div>
          <ul>
            {footerContext.links.map(
              ({ props, name, event, img, alt, supportedLocales }) => {
                // Need to remove href from props as it will be updated, but if updated here it will overwrite the original
                // value as JS uses references as objects instead of the actual object.
                const { href, ...newProps } = props;
                newProps.href = get(messages, `footer.${href}`)
                  ? t(get(messages, `footer.${href}`))
                  : href;
                const currentLocale = getCurrentLocale();

                if (
                  supportedLocales &&
                  !supportedLocales.includes(currentLocale)
                ) {
                  return null;
                }

                return (
                  <li key={name}>
                    <a {...newProps} onClick={trackGenericClick(event)}>
                      {t(get(messages, `footer.${name}`), {
                        date: new Date().getFullYear()
                      })}
                      {img && (
                        <LazyLoadImage
                          className="adchoices-icon"
                          src={getAssetUrl(img)}
                          alt={alt}
                          threshold={200}
                        />
                      )}
                    </a>
                  </li>
                );
              }
            )}
          </ul>
        </div>
        {!isServer() && supportsCmp && (
          <div className="footer-bottom">
            <div id="teconsent"></div>
          </div>
        )}
      </nav>
    </footer>
  );
};

Footer.propTypes = {
  href: PropTypes.string,
  showUpperFooter: PropTypes.bool
};

Footer.defaultProps = {
  showUpperFooter: true
};

export default memo(Footer, isEqual);
