import { getConfig } from '../config/portal';
import { multiAdsPayload } from '../utils/ads/adsUtils';
import * as httpClient from '../utils/httpClient';

export const getMultiAds = async (pageContext, forceAdButlerAds, cookies, customAdParams, urlParams, dealer, listing, pageKey, caller) => {
  const cfg = getConfig();
  const abTestContext = pageContext?.abTestContext || pageContext;
  const requestContext = pageContext?.requestContext;
  const body = multiAdsPayload(cfg, abTestContext, forceAdButlerAds, cookies, customAdParams, urlParams, dealer, listing, pageKey);

  const path = `/ads/multi-ads`;
  const contextHeaders = requestContext?.headers;

  const apiClient = httpClient.getHttpClient(contextHeaders);
  return apiClient
  .post(path, body)
  .then((res) => res.data)
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(JSON.stringify({err: err, caller: caller}));
  });
};
